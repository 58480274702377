<template>
  <div>
    <h4>HISTORIAL DE PROFORMAS</h4>

    <div class="row mb-2">
      <div class="col">
        <button
          class="btn btn-primary dropdown-toggle mr-1"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i class="fa fa-plus"></i> Nuevo
        </button>
        <MenuLinks :clientId="client_id"></MenuLinks>
      </div>
    </div>

    <div class="row">
      <template v-for="(l, i) in list.data">
        <div
          class="col-lg-3 col-md-4 col-sm-6"
          :key="i"
          :title="`Sustento: ${l.reason_to_delete}`"
        >
          <router-link
            class=" card btn shadow-sm "
            :class="{
              disabled: l.deleted_at || !l.enable
            }"
            :to="`/dental-clinic/proforms/${l.id}`"
          >
            <div class="text-center my-5">
              <i class="fas fa-notes-medical fa-2x"></i>
              <br />
              <span
                v-show="l.used"
                class="badge badge-primary"
                :title="l.initiated_date"
              >
                <i class="fas fa-check"></i> Iniciada
              </span>
              <span
                v-show="!l.used"
                class="badge badge-danger"
                :title="l.initiated_date"
              >
                <i class="fas fa-check"></i>
                {{ l.deleted_at || !l.enable ? "Eliminado" : "No Iniciada" }}
              </span>
              <br />
              <span :title="l.specialty_name" class="text-nowrap">
                {{ l.specialty_name }}
              </span>
              <br />
              <app-span-money
                :quantity="l.budget"
                :moneyCode="1"
              ></app-span-money>
              <br />
              <span>{{ l.created_at | dateFormat }} </span>
            </div>
          </router-link>
        </div>
      </template>
    </div>
    <!-- <app-table-registers ref="tr" :getList="getList">
      <template slot="top">
        <span></span>
      </template>
      <template slot="table">
        <tr v-for="(l, i) in list.data" :key="i">
          <td>
            <span>{{ l.specialty_name }}</span> 
            <span>{{ l.used ? "iniciado" : "" }} </span>
          </td>
          <td>{{ l.created_at | dateFormat }}</td>
          <td>
            <router-link
              :to="`/dental-clinic/proforms/${l.id}`"
              class="btn btn-sm btn-light"
            >
              <i class="fa fa-link"></i> ver
            </router-link>
          </td>
        </tr>
      </template>
    </app-table-registers> -->
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import MenuLinks from "../proforms/MenuLinks";

export default {
  components: {
    MenuLinks
  },

  // directives
  // filters

  props: {
    client_id: {},
    used: { default: undefined }
  },

  data: () => ({
    list: {}
  }),

  computed: {
    //
  },

  watch: {
    client_id: {
      immediate: true,
      handler() {
        this.getList();
      }
    }
  },

  mounted() {
    //
  },

  methods: {
    getList(params) {
      return new Promise(rsv => {
        DentalClinicService.getProforms({
          client_id: this.client_id,
          ...params,
          used: this.used,
          deleted: 1
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
